<template>
  <div>
    <v-sheet class="mx-n4 mt-n4">
      <v-tabs v-model="active" color="text" background-color="primary-darken4">
        <v-tab
          v-for="tab in tabs"
          :key="getTabKey(tab)"
          :to="{ params: { tab: tab.name, id: tab.id } }"
        >
          {{ tab.label }}
        </v-tab>
      </v-tabs>
      <v-tabs-items :value="activeTab" touchless>
        <v-tab-item
          v-for="tab in tabs"
          :key="'tab-item-' + tab.name + '-' + tab.id"
          :value="tab.name + (tab.id ? '/' + tab.id : '')"
          class="ma-4 mb-0"
        >
          <component
            :is="tab.component"
            v-if="
              $route.params.tab === tab.name &&
              (!$route.params.id || $route.params.id == tab.id)
            "
          />
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </div>
</template>

<script>
import Report from "./Report"
import Sales from "./Sales"
import Analytics from "./Analytics"
import { mapGetters, mapState, mapActions } from "vuex"

export default {
  name: "reporting-index",
  components: {
    Report,
    Sales,
    Analytics,
  },
  data() {
    return {
      active: "orders",
    }
  },
  computed: {
    ...mapState(["currentUserRole", "restaurants", "settings"]),
    ...mapGetters(["getSettings", "isNextGenUser"]),
    inventoryEnabled() {
      return this.getSettings?.inventoryEnabled
    },
    activeTab: {
      get() {
        return (
          this.$route.params.tab +
          (this.$route.params.id ? "/" + this.$route.params.id : "")
        )
      },
    },
    tabs() {
      const prefix = "" //"/reporting/"
      const tabs = []
      if (this.isNextGenUser) {
        tabs.push({
          name: prefix + "nextgen",
          label: "NextGen Report",
          component: "sales",
        })
      }

      if (this.currentUserRole === "Admin" && this.restaurants?.length > 1) {
        tabs.push({
          name: prefix + "org",
          label: this.isNextGenUser
            ? this.settings.organizationName
            : "Commission Report",
          component: "sales",
        })
      }
      // if (this.inventoryEnabled) {
      //   tabs.push({
      //     name: prefix + "report",
      //     label: "Advanced Report",
      //     component: "report",
      //   })
      // }
      this.restaurants.forEach(restaurant => {
        tabs.push({
          name: prefix + "restaurant",
          id: restaurant.id,
          label:
            this.currentUserRole === "Admin" && this.restaurants?.length > 1
              ? restaurant.name
              : "Sales Report",
          component: "sales",
        })
      })
      tabs.push({
        name: prefix + "analytics",
        label: "Analytics",
        component: "analytics",
      })
      return tabs
    },
  },
  mounted() {
    this.fetchInventoryData()
    if (this.tabs.length && !this.$route.params.tab) {
      this.$router.push({ params: { tab: this.tabs[0].name } })
    }
  },
  methods: {
    ...mapActions(["fetchInventoryData"]),
    getTabKey(tab) {
      return "tab-" + tab.name + (tab.id ? "-" + tab.id : "")
    },
  },
}
</script>
