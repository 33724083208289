<template>
  <div>
    <h2 class="mb-8 mt-4 page-title">Advanced Sales Report</h2>
    <v-row justify="start">
      <v-col cols="4">
        <custom-date-range-filter @change="dates = $event" />
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="categoryFilters"
          :multiple="channelFilters.length < 2"
          outlined
          :disabled="xAxisType == 'byCategory'"
          :menu-props="{ offsetY: true }"
          placeholder="All Categories"
          :items="allCategories"
          :hint="categoryFilterHint"
        />
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="channelFilters"
          :multiple="categoryFilters.length < 2"
          outlined
          :menu-props="{ offsetY: true }"
          :items="channels"
          item-text="name"
          item-value="id"
          placeholder="All Channels"
          :hint="channelFilterHint"
        />
      </v-col>
    </v-row>
    <v-row justify="start" class="my-6">
      <v-col cols="3">
        <v-select
          v-model="xAxisType"
          :items="xAxisOptions"
          outlined
          label="Select x axis"
          item-text="name"
          item-value="value"
          @click="
            channelFilters = []
            categoryFilters = []
          "
        />
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="graphToView"
          outlined
          :items="graphDataOptions"
          label="Select Graph Type"
          :disabled="xAxisType != 'byDate'"
        />
      </v-col>
    </v-row>
    <v-apex-charts
      :type="
        xAxisType == 'byDate'
          ? lineChartOptions.chart.type
          : barChartOptions.chart.type
      "
      :options="xAxisType == 'byDate' ? lineChartOptions : barChartOptions"
      :series="graphData"
      height="500"
    />
    <v-data-table
      :headers="getTableData.headers"
      :items="getTableData.data"
      sort-by="date"
      :sort-desc="true"
      :show-expand="xAxisType == 'byDate'"
      single-expand
      :expanded="expanded"
      item-key="id"
      @item-expanded="getOrderDetails"
    >
      <template v-slot:expanded-item="{ headers }">
        <td :colspan="headers.length">
          <v-row justify="center">
            <v-col cols="8">
              <v-card class="my-2">
                <v-data-table
                  :headers="saleDetailsHeaders"
                  :items="orderDetails"
                  hide-default-footer
                />
              </v-card>
            </v-col>
          </v-row>
        </td>
      </template>
      <template v-slot:[`footer.page-text`]>
        <vue-json-to-csv :json-data="getTableData.data" csv-title="Sales Report">
          <v-btn icon><v-icon>mdi-download</v-icon></v-btn>
        </vue-json-to-csv>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CustomDateRangeFilter from "../../shared/forms/datetime/CustomDateRangeFilter.vue"
import { mapState } from "vuex"
import VueApexCharts from "vue-apexcharts"
import VueJsonToCsv from "vue-json-to-csv"

export default {
  name: "report",
  components: {
    CustomDateRangeFilter,
    "v-apex-charts": VueApexCharts,
    "vue-json-to-csv": VueJsonToCsv,
  },
  data() {
    return {
      categoryFilters: [],
      allCategories: null,
      channelFilters: [],
      xAxisType: "byDate",
      tableHeaders: [
        { text: "Date", value: "date" },
        { text: "Gross Sales ($)", value: "grossSales" },
        { text: "Refunds ($)", value: "refunds" },
        { text: "Discounts ($)", value: "discounts" },
        { text: "Taxes ($)", value: "taxes" },
        { text: "Tips ($)", value: "tips" },
        { text: "Net Sales ($)", value: "netSales" },
        { text: "Number of Orders", value: "numberOfOrders" },
        { text: "Average Ticket Size", value: "ticketSize" },
        { text: "New Customer", value: "numNewCustomer" },
      ],
      barGraphHeaders: [
        { text: "Menu Item", value: "menuItem" },
        { text: "Sales Quantity", value: "amountOrdered" },
        { text: "Sales Value", value: "totalSales" },
        { text: "Average Quantity /order", value: "avgQuantityPerOrder" },
        { text: "% New Customers", value: "percNewCustomers" },
      ],
      expanded: [],
      saleDetailsHeaders: [
        { text: "Quantity", value: "quantity" },
        { text: "Menu Item Name", value: "menuItemName" },
        { text: "Category", value: "category" },
        { text: "Restaurant", value: "restaurant" },
      ],
      categoryTableHeaders: [
        { text: "Category Name", value: "categoryName" },
        { text: "Sales Quantity", value: "quantity" },
        { text: "Sales Value", value: "totalSales" },
      ],
      orderDetails: [],
      graphDataOptions: [
        "Sales",
        "Number of Orders",
        "Average Ticket Size",
        "Customers",
      ],
      xAxisOptions: [
        { name: "By Date", value: "byDate" },
        { name: "By Menu Item", value: "byItem" },
        { name: "By Category", value: "byCategory" },
      ],
      graphToView: "Sales",
      tableData: [],
      dates: [],
      barData: [],
      totAmountOfItemsOrdered: null,
    }
  },
  computed: {
    ...mapState(["channels", "orders", "menus"]),
    lineChartOptions() {
      return {
        chart: {
          type: "area",
          toolbar: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          labels: {
            format: "dd MMM yyyy",
            show: true,
          },
          tickAmount: this.tableData.map(data => data.date).length,
          categories: this.tableData.map(data => data.date).sort(),
        },
        markers: {
          size: 5,
        },
        tooltip: {
          x: {
            format: "dd/MM/yy",
          },
        },
      }
    },
    channelFilterHint() {
      return this.categoryFilters.length > 0
        ? "You can only do comparisons by either channel OR category. Because you have selected multiple categories, you can only filter by a single channel. If you want to do comparison across your channels, please select only one category."
        : ""
    },
    categoryFilterHint() {
      return this.channelFilters.length > 0
        ? "You can only do comparisons by either channel OR category. Because you have selected multiple channels, you can only filter by a single categor. If you want to do comparison across your categories, please select only one channel."
        : ""
    },
    barChartOptions() {
      return {
        chart: {
          type: "bar",
          toolbar: false,
        },
        dataLabels: {
          enabled: false,
        },
        axisTicks: {
          show: true,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top",
              enabled: false,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
            },
          },
        },
        xaxis: {
          type: "category",
          labels: {
            show: true,
          },
          tickAmount: this.barData.length,
          categories:
            this.xAxisType == "byItem"
              ? this.barData.map(point => point.menuItem)
              : this.barData.map(data => data.categoryName),
        },
      }
    },
    graphData() {
      let data = []
      if (this.xAxisType == "byDate") {
        if (this.graphToView == "Sales") {
          data = [
            {
              name: "Gross Sales",
              data: this.tableData.map(data => data.grossSales),
            },
            {
              name: "Net Sales",
              data: this.tableData.map(data => data.netSales),
            },
          ]
        } else if (this.graphToView == "Number of Orders") {
          data = [
            {
              name: "Number of Orders",
              data: this.tableData.map(data => data.numberOfOrders),
            },
          ]
        } else if (this.graphToView == "Average Ticket Size") {
          data = [
            {
              name: "Average Ticket Size",
              data: this.tableData.map(data => data.ticketSize),
            },
          ]
        } else if (this.graphToView == "Customers") {
          data = [
            {
              name: "Number of New Customers",
              data: this.tableData.map(data => data.numNewCustomer),
            },
            {
              name: "Number of Returning Customers",
              data: this.tableData.map(data => data.numReturningCustomer),
            },
          ]
        }
      } else {
        data = [
          {
            name: "Amount Ordered",
            data:
              this.xAxisType == "byItem"
                ? this.barData.map(data => data.amountOrdered)
                : this.barData.map(data => data.quantity),
          },
        ]
      }
      return data
    },
    getTableData() {
      if (this.xAxisType == "byDate") {
        return { headers: this.tableHeaders, data: this.tableData }
      } else if (this.xAxisType == "byItem") {
        return { headers: this.barGraphHeaders, data: this.barData }
      } else {
        return { headers: this.categoryTableHeaders, data: this.barData }
      }
    },
  },
  watch: {
    dates: {
      immediate: true,
      handler(newVal) {
        if (this.newVal || this.dates) {
          this.filterByDate(newVal)
        }
      },
    },
    channelFilters: {
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0) {
          if (this.xAxisType == "byDate") {
            this.tableData = this.tableData.filter(data =>
              this.channelFilters.includes(data.channelId)
            )
          } else if (this.xAxisType == "byItem") {
            this.barData = this.barData.filter(data =>
              this.channelFilters.includes(data.channelId)
            )
          } else {
            this.barData = this.barData.filter(data =>
              this.channelFilters.some(channel => data.channelId.includes(channel))
            )
          }
        } else {
          if (this.xAxisType == "byDate") {
            this.formatTableData(this.orders)
          } else if (this.xAxisType == "byItem") {
            this.formatBarGraphData(this.orders)
          } else {
            this.formatCategoryReportData(this.orders)
          }
        }
      },
    },
    categoryFilters: {
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0) {
          if (this.xAxisType == "byDate") {
            this.formatTableData(this.orders)
            this.tableData = this.tableData.filter(data =>
              data.orderCategories.some(orderCategory =>
                newVal.includes(orderCategory)
              )
            )
          } else {
            this.formatBarGraphData(this.orders)
            this.barData = this.barData.filter(data =>
              newVal.includes(data.category)
            )
          }
        } else {
          if (this.xAxisType == "byDate") {
            return this.formatTableData(this.orders)
          } else {
            this.formatBarGraphData(this.orders)
          }
        }
      },
    },
    xAxisType: {
      immedate: true,
      handler(newVal) {
        if (newVal == "byItem") {
          this.formatBarGraphData(this.orders)
        } else if (newVal == "byCategory") {
          this.formatCategoryReportData(this.orders)
        }
      },
    },
  },
  async mounted() {
    this.getAllCategories()
    this.formatTableData(this.orders)
  },
  methods: {
    getAllCategories() {
      this.allCategories = []
      this.menus.forEach(menu => {
        menu.menuContent.forEach(menuContentItem =>
          this.allCategories.push(menuContentItem.categoryName)
        )
      })
    },
    formatTableData(orders) {
      this.tableData = []
      let numOrders = 0
      let currDateIndex = {}
      orders.forEach(order => {
        if (
          this.tableData.filter(
            data => data.date == order.orderDate.toDate().toISOString().split("T")[0]
          ).length == 0
        ) {
          numOrders = this.getNumberOfOrders(order.userId)
          this.tableData.push({
            id: order.id,
            date: order.orderDate.toDate().toISOString().split("T")[0],
            grossSales: order.priceData.total.toFixed(2),
            netSales: order.priceData.subTotal.toFixed(2),
            numberOfOrders: numOrders,
            ticketSize: order.orderItems.length,
            refunds: 0,
            discounts: order.priceData.discount.toFixed(2),
            taxes: (order.priceData.totalTaxes || order.priceData.taxes).toFixed(2),
            tips: order.priceData.tip.toFixed(2),
            numNewCustomer: numOrders > 0 ? 0 : numOrders,
            numReturningCustomer: numOrders > 0 ? numOrders : 0,
            channelId: order.channelId,
            orderCategories: this.getOrderCategories(order),
          })
        } else {
          currDateIndex = this.tableData.findIndex(
            data => data.date == order.orderDate.toDate().toISOString().split("T")[0]
          )
          this.tableData[currDateIndex].grossSales =
            parseFloat(this.tableData[currDateIndex].grossSales) +
            parseFloat(order.priceData.total)
          this.tableData[currDateIndex].netSales =
            parseFloat(this.tableData[currDateIndex].netSales) +
            parseFloat(order.priceData.subTotal)
          this.tableData[currDateIndex].numberOfOrders =
            parseFloat(this.tableData[currDateIndex].numberOfOrders) +
            parseFloat(this.getNumberOfOrders(order.userId))
          this.tableData[currDateIndex].ticketSize =
            parseFloat(this.tableData[currDateIndex].ticketSize) +
            parseFloat(order.orderItems.length)
          this.tableData[currDateIndex].discounts =
            parseFloat(this.tableData[currDateIndex].discounts) +
            parseFloat(order.priceData.discount)
          this.tableData[currDateIndex].taxes =
            parseFloat(this.tableData[currDateIndex].taxes) +
            parseFloat(order.priceData.totalTaxes || order.priceData.taxes)
          this.tableData[currDateIndex].tips =
            parseFloat(this.tableData[currDateIndex].tips) +
            parseFloat(order.priceData.tips)
        }
      })
    },
    getNumberOfOrders(userId) {
      let count = 0
      this.orders.forEach(order => {
        if (order.userId == userId) {
          count += 1
        }
      })
      return count
    },
    getOrderDetails({ item }) {
      this.orderDetails = []
      let orderExpanded = this.orders.find(order => order.id == item.id)
      orderExpanded.orderItems.forEach(orderItem => {
        this.orderDetails.push({
          quantity: orderItem.quantity,
          menuItemName: orderItem.name,
          restaurant: orderItem.restaurantId,
          category: orderItem.menuItemCategory,
        })
      })
    },
    filterByDate(dates) {
      let filteredOrders = this.orders.filter(
        order =>
          order.orderDate.toDate() >= new Date(dates[0]) &&
          order.orderDate.toDate() <= new Date(dates[1])
      )
      if (this.xAxisType == "byDate") {
        this.formatTableData(filteredOrders)
      } else if (this.xAxisType == "byItem") {
        this.formatBarGraphData(filteredOrders)
      } else {
        this.formatCategoryReportData(filteredOrders)
      }
    },
    filterByChannel() {
      let filteredOrders = this.orders.filter(order =>
        this.channelFilters.includes(order.channelId)
      )
      this.formatTableData(filteredOrders)
    },
    getOrderCategories(order) {
      let orderCategories = []
      order.orderItems.forEach(orderItem =>
        orderCategories.push(orderItem.menuItemCategory)
      )
      return [...new Set(orderCategories)]
    },
    getTotAmountOfItemsOrdered(orders) {
      this.totAmountOfItemsOrdered = 0
      orders.forEach(
        order => (this.totAmountOfItemsOrdered += order.orderItems.length)
      )
    },
    formatBarGraphData(orders) {
      this.getTotAmountOfItemsOrdered(orders)
      this.barData = []
      let indexOfMenuItem = 0
      orders.forEach(order => {
        order.orderItems.forEach(orderItem => {
          if (
            this.barData.filter(data => data.menuItem == orderItem.name).length > 0
          ) {
            indexOfMenuItem = this.barData.findIndex(
              data => data.menuItem == orderItem.name
            )
            this.barData[indexOfMenuItem].amountOrdered += orderItem.quantity
            this.barData.totalSales +=
              parseFloat(orderItem.quantity) *
              parseFloat(orderItem.priceMoney.amount)
            this.barData[indexOfMenuItem].percNewCustomers =
              order.userNumberOfPrevOrders > 1
                ? (this.barData[indexOfMenuItem].percNewCustomers += 0)
                : (this.barData[indexOfMenuItem].percNewCustomers += 1)
          } else {
            this.barData.push({
              menuItem: orderItem.name,
              amountOrdered: orderItem.quantity,
              category: orderItem.menuItemCategory,
              channelId: order.channelId,
              totalSales:
                parseFloat(orderItem.priceMoney.amount) *
                parseFloat(orderItem.quantity),
              avgQuantityPerOrder:
                parseFloat(orderItem.quantity) / parseFloat(order.orderItems.length),
              percNewCustomers: order.userNumberOfPrevOrders > 1 ? 0 : 1,
            })
          }
        })
      })
      this.barData.forEach(data => {
        data.quantityOrderedOverall =
          data.amountOrdered + "/" + this.totAmountOfItemsOrdered
        data.avgQuantityPerOrder = data.avgQuantityPerOrder.toFixed(2) * 100 + " %"
        data.totalSales = "$ " + data.totalSales / 100
        data.percNewCustomers = "% " + data.percNewCustomers / data.amountOrdered
      })
    },
    formatCategoryReportData(orders) {
      this.barData = []
      let indexOfCategory = 0
      orders.forEach(order => {
        order.orderItems.forEach(orderItem => {
          if (
            this.barData.filter(
              data => data.categoryName == orderItem.menuItemCategory
            ).length > 0
          ) {
            indexOfCategory = this.barData.findIndex(
              data => data.categoryName == orderItem.menuItemCategory
            )
            this.barData[indexOfCategory].quantity += parseFloat(orderItem.quantity)
            this.barData[indexOfCategory].totalSales += parseFloat(
              orderItem.priceMoney.amount
            )
            this.barData[indexOfCategory].channelId.push(order.channelId)
          } else {
            this.barData.push({
              categoryName: orderItem.menuItemCategory,
              quantity: parseFloat(orderItem.quantity),
              totalSales: parseFloat(orderItem.priceMoney.amount),
              channelId: [order.channelId],
            })
          }
        })
      })
      this.barData.forEach(data => {
        data.totalSales = "$" + data.totalSales / 100
        data.channelId = [...new Set(data.channelId)]
      })
    },
  },
}
</script>
