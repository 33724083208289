<template>
  <v-card style="width: 400px" class="mb-n2">
    <v-card-title>Order details</v-card-title>
    <v-card-subtitle>{{ order.id }}</v-card-subtitle>
    <v-card-text>
      <v-list
        style="background: transparent; width: calc(100% + 30px)"
        class="mx-n4"
        dense
      >
        <v-list-item>
          <v-list-item-content>
            <v-row>
              <v-col class="py-1">Date</v-col>
              <v-col class="py-1 text-right">
                {{ orderDate }}
                <br />
                <span class="text--disabled">{{ orderTime }}</span>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-row>
              <v-col class="py-1">Source</v-col>
              <v-col class="py-1 text-right">
                <order-detail-source :order="order" />
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-row>
              <v-col class="py-1 pt-2">Customer</v-col>
              <v-col class="py-1 pt-2 text-right">
                <order-detail-customer :order="order" right-align class="mt-n4" />
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-expansion-panels class="mt-n4 light-background" style="width: 400px" multiple>
      <order-expandable-panel :order="order" />
    </v-expansion-panels>
  </v-card>
</template>

<script>
import moment from "moment"
import OrderExpandablePanel from "@/AuthenticatedContent/shared/OrderExpandablePanel"
import OrderDetailSource from "@/AuthenticatedContent/Sales/Orders/fields/OrderDetailSource"
import OrderDetailCustomer from "@/AuthenticatedContent/Sales/Orders/fields/OrderDetailCustomer"

export default {
  name: "customer-orders",
  components: {
    OrderExpandablePanel,
    OrderDetailSource,
    OrderDetailCustomer,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    orderDate() {
      return typeof this.order.orderDate?.toDate === "function"
        ? moment(this.order.orderDate?.toDate()).format("MMM DD, YYYY")
        : moment(this.order.orderDate).format("MMM DD, YYYY")
    },
    orderTime() {
      return typeof this.order.orderDate?.toDate === "function"
        ? moment(this.order.orderDate?.toDate()).format("hh:mm A")
        : moment(this.order.orderDate).format("hh:mm A")
    },
  },
}
</script>

<style scoped>
.v-list {
  height: 170px;
  overflow-y: auto !important;
  max-width: 400px !important;
}
</style>
