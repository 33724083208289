<template>
  <v-card class="elevation-0" color="transparent">
    <v-card-title class="pb-2 text-body-2 lighttext--text">
      {{ title }}
      <v-icon small>mdi-chevron-right</v-icon>
    </v-card-title>
    <v-card-text class="text-h6 font-weight-black">
      {{ stat }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "summary-stat-panel",
  props: {
    title: {
      type: String,
      required: true,
    },
    stat: {
      type: [String, Number],
      required: true,
    },
  },
}
</script>
