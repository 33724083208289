var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panels',{attrs:{"flat":"","accordion":""}},[_c('v-expansion-panel',{staticStyle:{"background":"transparent !important"},attrs:{"readonly":!_vm.order.channelData.deliveryAddress ||
      !_vm.order.channelData.deliveryAddress.source}},[_c('v-expansion-panel-header',{staticClass:"pa-0",class:{ 'justify-end': _vm.rightAlign },attrs:{"hide-actions":!_vm.order.channelData.deliveryAddress ||
        !_vm.order.channelData.deliveryAddress.source}},[_vm._v(" "+_vm._s(_vm.customerInfo)+" ")]),(
        _vm.order.channelData.deliveryAddress &&
        _vm.order.channelData.deliveryAddress.source
      )?_c('v-expansion-panel-content',{staticClass:"mx-n6"},[_c('p',{staticClass:"mt-n3 mb-0"},[_vm._v(" "+_vm._s(_vm.order.channelData.deliveryAddress.source + " " + _vm.order.channelData.deliveryAddress.extraAddressInfo)+" ")])]):_vm._e()],1),_c('v-expansion-panel',{staticClass:"mt-n4",staticStyle:{"background":"transparent !important"}},[_c('v-expansion-panel-header',{staticClass:"pa-0",attrs:{"hide-actions":""}},[(
          _vm.order.orderNumber ||
          (_vm.order.channelData &&
            (_vm.order.channelData.referenceId || _vm.order.channelData.appDisplayId))
        )?_c('span',{staticClass:"text--disabled",class:{ 'text-right': _vm.rightAlign },attrs:{"title":_vm.clickOrTapText + ` to show order IDs`}},[_vm._v(" "+_vm._s((_vm.isNextGenOrder && `Order #` + _vm.order.orderNumber) || (_vm.order.channelData && `Ref. ` + (_vm.order.channelData.referenceId || _vm.order.channelData.appDisplayId)))+" "),(_vm.order.doordashData && _vm.order.doordashData.supportReference)?_c('span',[_c('br'),_vm._v(" DoorDash Ref. "+_vm._s(_vm.order.doordashData.supportReference)+" ")]):_vm._e()]):_c('span',{staticClass:"text--disabled",attrs:{"title":_vm.order.id}},[_vm._v(" "+_vm._s(_vm.order.id)+" ")])]),(
        _vm.order.orderNumber ||
        (_vm.order.channelData &&
          (_vm.order.channelData.referenceId || _vm.order.channelData.appDisplayId))
      )?_c('v-expansion-panel-content',{staticClass:"mx-n6 mt-n6",staticStyle:{"min-height":"20px"}},[_c('br'),_c('p',[_c('span',{staticClass:"text--disabled"},[_vm._v("NextGen Order ID:")]),_c('br'),_vm._v(" "+_vm._s(_vm.order.id)+" ")]),_c('p',[_c('span',{staticClass:"text--disabled"},[_vm._v("Channel Order ID:")]),_vm._v(" "+_vm._s(_vm.order.channelData.appOrderId || _vm.order.channelData.referenceId)+" ")])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }