/* eslint-disable prettier/prettier */

// Libraries
import { mapState } from "vuex"
import moment from "moment"

// Mixins 
import taxFunctions from "./taxFunctions.js"
import getOrderRefunds from "../../Sales/mixins/getOrderRefunds.js"
import formatPrice from "@/mixins/formatPrice.js"
import capitalize from "@/mixins/capitalize.js"

export default {
  mixins: [taxFunctions, getOrderRefunds, formatPrice, capitalize],
  methods: {
    getOrderData(order) { 
      const orderData = {
        type: order.type,
        id: order.id,
        date: moment(order.orderDate?.toDate()).format(this.dateFormat),
        orderNumber: order.orderNumber || "",
        source: this.capitalize(order.channelData?.appType) || "",
        status: order.status,
        subtotal: this.formatPrice(order.priceData?.subTotal),
        discount: this.formatPrice(-1 * order.priceData?.discount || 0),
        subtotalWithDiscount: this.formatPrice(order.priceData?.subTotal - (order.priceData?.discount || 0) || 0),
        taxes: this.formatPrice(order.priceData?.totalTaxes || order.priceData?.taxes),
        tips: this.formatPrice(order.priceData?.tip),
        serviceCharge: this.formatPrice(order.priceData?.serviceCharge),
        totalPaid: this.formatPrice(order.priceData?.total), 
        organizationFeeSubTotal: this.formatPrice(order.priceData?.organizationFeeSubTotal),
        organizationFeeGST: this.formatPrice(order.priceData?.organizationFeeGST),
        organizationFeePST: this.formatPrice(order.priceData?.organizationFeePST),
        organizationFee: this.formatPrice(order.priceData?.organizationFee),
        organizationDiscount: this.formatPrice(order.priceData?.organizationDiscount),
        organizationTip: this.formatPrice(order.priceData?.organizationTip),
        organizationPaymentProcessingFee: this.formatPrice(-order.priceData?.organizationPaymentProcessingFee || -order.priceData?.organizationStripeFee), 
        paymentProcessingFeeRefund: this.formatPrice(0),
        orgTotalReceived: order.payouts?.totalOrganizationPayout
          ? this.formatPrice(order.payouts.totalOrganizationPayout)
          : this.formatPrice(
              // for pre-dec-2023 orders
              order.priceData?.total - order.priceData?.stripeFee - this.getRestaurantTotalNetTotals(order) - order.priceData?.platformFee + order.priceData?.platformStripeFee - order.priceData?.serviceCharge
            ),
        orgTotalReceivedCalc: this.formatPrice(
          order.priceData?.organizationFeeSubTotal + order.priceData?.organizationTip + order.priceData?.organizationFeeGST + order.priceData?.organizationFeePST - order.priceData?.organizationDiscount - order.priceData?.platformFee - order.priceData?.platformFeeGST - order.priceData?.platformFeePST - order.priceData?.serviceCharge - order.priceData?.organizationStripeFee
        ),
        platformFee: this.formatPrice(-order.priceData?.platformFee),
        platformFeeGST: this.formatPrice(-order.priceData?.platformFeeGST),
        platformFeePST: this.formatPrice(-order.priceData?.platformFeePST),
        platformFeeSubTotal: this.formatPrice(-order.priceData?.platformFeeSubTotal),
        platformFeePaymentProcessingFee: this.formatPrice(order.priceData?.platformStripeFee), 
        nextGenFeeSubTotal: this.formatPrice(order.priceData?.platformFeeSubTotal),
        nextGenFeeGST: this.formatPrice(order.priceData?.platformFeeGST),
        nextGenFeePST: this.formatPrice(order.priceData?.platformFeePST),
        nextGenFee: this.formatPrice(order.priceData?.platformFee),
        nextGenFeePaymentProcessingFee: this.formatPrice(-order.priceData?.platformPaymentProcessingFee || -order.priceData?.platformStripeFee), 
        nextGenTotalPayout: this.formatPrice(
          order.payouts?.totalPlatformPayout || order.priceData.platformFee - order.priceData.platformStripeFee // for pre-dec-2023 orders
        ),
        paymentProcessingFee: this.formatPrice(-order.priceData?.paymentProcessingFee || -order.priceData.stripeFee),
        totalDistributed: this.formatPrice(this.getOrderTotalDistributed(order.priceData)),
      }
      orderData["orgTotalReceivedCheck"] = this.formatPrice(this.getNumberFromPrice(orderData.orgTotalReceivedCalc) - this.getNumberFromPrice(orderData.orgTotalReceived))
      orderData["taxesList"] = order.priceData?.taxesList
      this.getUniqueTaxes()?.forEach(tax => {
        orderData[this.getTaxId(tax)] = this.formatPrice(
          // reduce the taxes list to find the sum for each tax
          order.priceData?.taxesList
            .filter(t => {
              const taxData = this.taxes.find(t2 => {
                return t2.id === t.id
              })
              return this.isSameTax(taxData, tax)
            })
            .reduce((total, t) => {
              return total + t.amount
            }, 0)
        )
      })
      this.restaurants.forEach(restaurant => {
        const restaurantData = order.restaurants?.find(r => r.id === restaurant.id)
        if (restaurantData) {
          orderData["numRestaurants"] = order.restaurants?.length
          orderData["posOrderId" + restaurant.id] = restaurantData.posOrderId
          orderData["subTotal" + restaurant.id] = this.formatPrice(restaurantData?.subTotal)
          orderData["discount" + restaurant.id] = this.formatPrice(restaurantData?.discount)

          const taxAmounts = {}
          this.taxes.forEach(tax => {
            if (tax.restaurantIds.includes(restaurant.id)) {
              const relevantTaxes = order.priceData?.taxesList.filter(t => t.id === tax.id)
              if (relevantTaxes?.length) {
                const taxId = this.getTaxId(tax, restaurant)
                const totalTaxAmount = relevantTaxes.reduce((sum, t) => sum + t.amount, 0)
                taxAmounts[taxId] = (taxAmounts[taxId] || 0) + totalTaxAmount
              }
            }
          })
          for (const [taxId, amount] of Object.entries(taxAmounts)) {
            orderData[taxId] = this.formatPrice(amount)
          }

          orderData["taxes" + restaurant.id] = this.formatPrice(restaurantData.totalTaxes)
          orderData["tips" + restaurant.id] = this.formatPrice(
            restaurantData.tip
          )
          orderData["totalPaid" + restaurant.id] = this.formatPrice(
            restaurantData.grossTotal
          )

          orderData["organizationFeeSubTotal" + restaurant.id] = this.formatPrice(-restaurantData.organizationFee - restaurantData.organizationFeeGST - restaurantData.organizationFeePST)
          orderData["organizationFeeGST" + restaurant.id] = this.formatPrice(-restaurantData.organizationFeeGST)
          orderData["organizationFeePST" + restaurant.id] = this.formatPrice(-restaurantData.organizationFeePST)
          orderData["organizationFee" + restaurant.id] = this.formatPrice(-restaurantData.organizationFee)
          orderData["paymentProcessingFee" + restaurant.id] = this.formatPrice(-restaurantData.paymentProcessingFee || -restaurantData.stripeFee)
          orderData["netTotal" + restaurant.id] = this.formatPrice(restaurantData.netTotal)
          orderData["netTotalCalc" + restaurant.id] = this.formatPrice(restaurantData.grossTotal - (restaurantData.organizationFee || 0) - (restaurantData.paymentProcessingFee || restaurantData.stripeFee || 0))
          orderData["netTotalCheck" + restaurant.id] = this.formatPrice(this.getNumberFromPrice(orderData["netTotal" + restaurant.id]) - this.getNumberFromPrice(orderData["netTotalCalc" + restaurant.id]))
        } else {
          // fill in empty values for restaurants that don't have data
          orderData["numRestaurants"] = ""
          orderData["posOrderId" + restaurant.id] = ""
          orderData["subTotal" + restaurant.id] = ""
          orderData["discount" + restaurant.id] = ""
          this.taxes.forEach(tax => {
            const taxId = this.getTaxId(tax, restaurant)
            orderData[taxId] = ""
          })
          orderData["taxes" + restaurant.id] = ""
          orderData["tips" + restaurant.id] = ""
          orderData["totalPaid" + restaurant.id] = ""
          orderData["organizationFee" + restaurant.id] = ""
          orderData["paymentProcesingFee" + restaurant.id] = ""
          orderData["netTotal" + restaurant.id] = ""
        }
      })
      // return an object with the keys sorted recursively alphabetically
      const sortObj = o =>
        Object.keys(o)
          .sort()
          .reduce((r, k) => ((r[k] = o[k] && typeof o[k] === "object" ? (o[k].hasOwnProperty("nanoseconds") ? moment(o[k].toDate()).format(this.dateFormat) : sortObj(o[k])) : o[k]), r), {})
      orderData.fullOrder = order ? sortObj(order) : order
      return orderData
    },
    getRefundData(refund) {
      if (!refund.order) {
        // get the associated order
        refund.order = this.allOrders.find(o => o.id === refund.orderId)
      }

      if (!refund.order) {
        // get the associated order
        refund.order = this.allOrders.find(o => o.id === refund.orderId)
      }

      const refundData = {
        type: refund.type,
        refundId: refund.id,
        id: refund.orderId,
        date: moment(refund.order.orderDate?.toDate()).format(this.dateFormat),
        refundDate: moment(refund.refundDate?.toDate()).format(this.dateFormat),
        orderNumber: refund.order?.orderNumber || "",
        source: this.capitalize(refund.order?.channelData?.appType) || "",
        status: refund.status,
        subtotal: this.formatPrice(refund.priceData?.subTotal),
        discount: this.formatPrice(-refund.priceData?.discount),
        subtotalWithDiscount: this.formatPrice(refund.priceData?.subTotal - (refund.priceData?.discount || 0)),
        taxes: this.formatPrice(refund.priceData?.totalTaxes || refund.priceData?.taxes),
        tips: this.formatPrice(refund.priceData?.tip),
        serviceCharge: this.formatPrice(refund.priceData?.serviceCharge),
        totalPaid: this.formatPrice(refund.priceData?.total), 
        organizationFeeSubTotal: this.formatPrice(-refund.order.priceData?.organizationFeeSubTotal),
        organizationFeeGST: this.formatPrice(-refund.order.priceData?.organizationFeeGST),
        organizationFeePST: this.formatPrice(-refund.order.priceData?.organizationFeePST),
        organizationFee: this.formatPrice(-refund.order.priceData?.organizationFee),
        organizationDiscount: this.formatPrice(-refund.order.priceData?.organizationDiscount),
        organizationTip: this.formatPrice(-refund.order.priceData?.organizationTip),
        organizationPaymentProcessingFee: this.formatPrice(refund.order.priceData?.organizationPaymentProcessingFee || refund.order.priceData?.organizationStripeFee), 
        paymentProcessingFeeRefund: this.formatPrice(refund.priceData?.paymentProcessingFeeRefund || -refund.order.priceData?.paymentProcessingFee || -refund.order.priceData.stripeFee),
        orgTotalReceived: this.formatPrice(
          -(refund.order.priceData?.total - this.getRestaurantTotalNetTotals(refund.order) - refund.order.priceData?.platformFee + refund.order.priceData?.platformStripeFee - refund.order.priceData?.serviceCharge - (refund.order.priceData?.organizationPaymentProcessingFee || refund.order.priceData?.organizationStripeFee))
        ),
        orgTotalReceivedCalc: this.formatPrice(   
          -(refund.order.priceData?.organizationFeeSubTotal + (refund.priceData?.paymentProcessingFeeRefund || refund.order.priceData.stripeFee) + refund.deductionData.organizationTip - refund.deductionData?.organizationDiscount - refund.deductionData.platformFee - refund.order.priceData?.platformFeeGST - refund.order.priceData?.platformFeePST - (refund.deductionData.deductServiceCharge || 0) - (refund.deductionData.organizationPaymentProcessingFee || refund.order.priceData.organizationStripeFee || 0))
        ),
        platformFee: this.formatPrice(refund.deductionData.platformFee),
        platformFeeGST: this.formatPrice(refund.order.platformFeeGST),
        platformFeePST: this.formatPrice(refund.order.platformFeePST),
        platformFeeSubTotal: this.formatPrice(refund.order.platformFeeSubTotal),
        platformFeePaymentProcessingFee: this.formatPrice(-refund.order.platformPaymentProcessingFee || -refund.order.platformStripeFee),
        nextGenFeeSubTotal: this.formatPrice(-refund.order.priceData?.platformFeeSubTotal),
        nextGenFeeGST: this.formatPrice(-refund.order.priceData?.platformFeeGST),
        nextGenFeePST: this.formatPrice(-refund.order.priceData?.platformFeePST),
        nextGenFee: this.formatPrice(-refund.deductionData.platformFee),
        nextGenFeePaymentProcessingFee: this.formatPrice(refund.order.priceData?.platformPaymentProcessingFee || refund.order.priceData?.platformStripeFee), 
        nextGenTotalPayout: this.formatPrice(-refund.order?.payouts?.totalPlatformPayout || -(refund.order.priceData.platformFee - refund.order.priceData.platformStripeFee)), // for pre-dec-2023 orders 
        paymentProcessingFee: this.formatPrice(0), // not applied to refunds
        totalDistributed: this.formatPrice(this.getRefundTotalDistributed(refund.priceData, refund.order.priceData)),
      }
      refundData["orgTotalReceivedCheck"] = this.formatPrice(this.getNumberFromPrice(refundData.orgTotalReceivedCalc) - this.getNumberFromPrice(refundData.orgTotalReceived))
      refundData["taxesList"] = refund.priceData?.taxesList
      this.getUniqueTaxes()?.forEach(tax => {
        refundData[this.getTaxId(tax)] = this.formatPrice(
          // reduce the taxes list to find the sum for each tax
          -refund.priceData?.taxesList
            .filter(t => {
              const taxData = this.taxes.find(t2 => {
                return t2.id === t.id
              })
              return this.isSameTax(taxData, tax)
            })
            .reduce((total, t) => {
              return total + t.amount
            }, 0)
        )
      })
      this.restaurants.forEach(restaurant => {
        const restaurantData = refund.restaurants?.find(r => r.id === restaurant.id)
        const orderRestaurantData = refund.order?.restaurants?.find(r => r.id === restaurant.id)
        if (restaurantData) {
          refundData["numRestaurants"] = refund.restaurants?.length
          refund["posOrderId" + restaurant.id] = orderRestaurantData.posOrderId
          refundData["subTotal" + restaurant.id] = this.formatPrice(restaurantData?.subTotal)
          refundData["discount" + restaurant.id] = this.formatPrice(restaurantData?.discount)

          const taxAmounts = {}
          this.taxes.forEach(tax => {
            if (tax.restaurantIds.includes(restaurant.id)) {
              const relevantTaxes = refund.priceData?.taxesList.filter(t => t.id === tax.id)
              if (relevantTaxes?.length) {
                const taxId = this.getTaxId(tax, restaurant)
                const totalTaxAmount = relevantTaxes.reduce((sum, t) => sum + t.amount, 0)
                taxAmounts[taxId] = (taxAmounts[taxId] || 0) + totalTaxAmount
              }
            }
          })
          // console.log('restaurant', restaurant.id, 'taxcount', Object.entries(taxAmounts).length)
          for (const [taxId, amount] of Object.entries(taxAmounts)) {
            refundData[taxId] = this.formatPrice(-amount)
          }

          refundData["taxes" + restaurant.id] = this.formatPrice(restaurantData.totalTaxes)
          refundData["tips" + restaurant.id] = this.formatPrice(
            restaurantData.tip
          )
          refundData["totalPaid" + restaurant.id] = this.formatPrice(
            restaurantData.grossTotal 
          )
          
          refundData["organizationFeeSubTotal" + restaurant.id] = this.formatPrice(orderRestaurantData.organizationFee - restaurantData.organizationFeeGST - orderRestaurantData.organizationFeePST)
          refundData["organizationFeeGST" + restaurant.id] = this.formatPrice(orderRestaurantData.organizationFeeGST)
          refundData["organizationFeePST" + restaurant.id] = this.formatPrice(orderRestaurantData.organizationFeePST)
          refundData["organizationFee" + restaurant.id] = this.formatPrice(orderRestaurantData.organizationFee)
          refundData["paymentProcessingFee" + restaurant.id] = this.formatPrice(orderRestaurantData.paymentProcessingFee || orderRestaurantData.stripeFee || 0) // for pre-square orders
          refundData["netTotal" + restaurant.id] = this.formatPrice(restaurantData.netTotal + orderRestaurantData.organizationFee)
          refundData["netTotalCalc" + restaurant.id] = this.formatPrice(restaurantData.grossTotal + orderRestaurantData.organizationFee + (orderRestaurantData.paymentProcessingFee || orderRestaurantData.stripeFee || 0))
          refundData["netTotalCheck" + restaurant.id] = this.formatPrice(this.getNumberFromPrice(refundData["netTotal" + restaurant.id]) - this.getNumberFromPrice(refundData["netTotalCalc" + restaurant.id]))
        } else {
          // fill in empty values for restaurants that don't have data
          refundData["numRestaurants"] = ""
          refundData["posOrderId" + restaurant.id] = ""
          refundData["subTotal" + restaurant.id] = ""
          refundData["discount" + restaurant.id] = ""
          this.taxes.forEach(tax => {
            const taxId = this.getTaxId(tax, restaurant)
            refundData[taxId] = ""
          })
          refundData["taxes" + restaurant.id] = ""
          refundData["tips" + restaurant.id] = ""
          refundData["totalPaid" + restaurant.id] = ""
          refundData["organizationFee" + restaurant.id] = ""
          refundData["paymentProcesingFee" + restaurant.id] = ""
          refundData["netTotal" + restaurant.id] = ""
        }
      })
      // return an object with the keys sorted recursively alphabetically
      const sortObj = o =>
        Object.keys(o)
          .sort()
          .reduce((r, k) => ((r[k] = o[k] && typeof o[k] === "object" ? (o[k].hasOwnProperty("nanoseconds") ? moment(o[k].toDate()).format(this.dateFormat) : sortObj(o[k])) : o[k]), r), {})
      refundData.fullOrder = refund ? sortObj(refund) : refund
      return refundData
    },
    getRefundTotalField(refunds, field, restaurantId = null) {
      return refunds.reduce((total, refund) => {
        if (restaurantId) {
          return total + parseInt(refund.restaurants.filter(r => r.id === restaurantId)?.[0]?.[field] || 0)
        } else {
          return total + parseInt(refund[field] || 0)
        }
      }, 0)
    },
    getRefundRestaurantField(restaurants, restaurantId, field) {
      return restaurants.filter(r => r.id === restaurantId)?.[0]?.[field] || 0
    },
    getOrderTotalDistributed(data) {
      return (data?.total || 0) - (data?.organizationFee || 0) - (data?.platformFee || 0) - (data?.stripeFee || 0) - (data?.serviceCharge || 0)
    },
    getRefundTotalDistributed(data, orderData) {
      return (data?.total || 0) + (orderData?.organizationFee || 0) + (orderData?.platformFee || 0) + (orderData?.stripeFee || 0) + (orderData?.serviceCharge || 0)
    },
    getRestaurantTotalNetTotals(data) {
      let total = 0
      data.restaurants.forEach(restaurant => {
        total += restaurant.netTotal || 0
      })
      return total
    },
  },
  computed: {
    ...mapState(["allOrders", "taxes", "restaurants"]),
    dateFormat() {
      return "YYYY-MM-DD HH:mm:ss"
    },
  },
}
