<template>
  <div>
    <v-row class="mt-2 mb-n4">
      <v-col cols="12" md="6">
        <h2 class="text-uppercase page-title mb-4">Sales Analytics</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card
          color="light-background"
          flat
          class="analytics-filter-wrapper mt-4 mx-0 py-1"
        >
          <v-col cols="12">
            <v-row>
              <v-col cols="3">
                <custom-date-range-filter
                  dense
                  show-times
                  :initial-dates="dates"
                  @change="datesRangeUpdated"
                />
              </v-col>
              <v-col cols="3">
                <custom-select
                  v-model="selectedSources"
                  :items="orderSourceOptions"
                  label="Sources"
                  multiple
                  dense
                />
              </v-col>
              <v-col
                v-if="
                  (!restaurants || restaurants.length <= 1) &&
                  (!menuItemCategoryOptions || !menuItemCategoryOptions.length)
                "
                cols="3"
              >
                <custom-select
                  v-model="selectedMenuItemIds"
                  :items="menuItemOptions"
                  label="Menu Item"
                  item-value="id"
                  item-text="name"
                  autocomplete
                  multiple
                  dense
                />
              </v-col>
            </v-row>
            <v-row
              v-if="
                (restaurants && restaurants.length > 1) ||
                (menuItemCategoryOptions && menuItemCategoryOptions.length)
              "
              class="mt-2"
            >
              <v-col v-if="restaurants && restaurants.length > 1" cols="3">
                <custom-select
                  v-model="selectedRestaurantIds"
                  :items="restaurants"
                  item-value="id"
                  item-text="name"
                  label="Restaurants"
                  multiple
                  dense
                />
              </v-col>
              <v-col
                v-if="menuItemCategoryOptions && menuItemCategoryOptions.length"
                cols="3"
              >
                <custom-select
                  v-model="selectedMenuItemCategoryIds"
                  :items="menuItemCategoryOptions"
                  label="Menu Item Category"
                  item-value="id"
                  item-text="title"
                  multiple
                  dense
                />
              </v-col>
              <v-col cols="3">
                <custom-select
                  v-model="selectedMenuItemIds"
                  :items="menuItemOptions"
                  label="Menu Item"
                  item-value="id"
                  item-text="name"
                  autocomplete
                  multiple
                  dense
                />
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <summary-stats
      :formatPrice="formatPrice"
      :filteredOrders="filteredOrders"
      :filteredOrdersPreviousPeriod="filteredOrdersPreviousPeriod"
      :filteredOrdersGrouped="filteredOrdersGrouped"
      :filteredOrdersGroupedPreviousPeriod="filteredOrdersGroupedPreviousPeriod"
      :loading="loading"
    ></summary-stats>
    <v-row>
      <v-col cols="12" xl="6">
        <v-card
          :loading="loading"
          outlined
          class="mt-4 mb-n6"
          shaped
          style="overflow: hidden"
        >
          <v-card-title class="text-h6 text-uppercase my-2">
            Advanced Report
          </v-card-title>
          <v-card-title>
            <v-row>
              <v-col cols="12" md="3">
                <custom-select
                  v-model="chartType"
                  class="mt-0"
                  :items="chartTypes"
                  label="Base Chart"
                  dense
                />
              </v-col>
              <v-col cols="12" md="3">
                <custom-select
                  v-if="stackedSplit || splitBy === ''"
                  v-model="chartType2"
                  class="mt-0"
                  :items="chartTypes"
                  :disabled="chartType === '' && chartType2 === ''"
                  label="Comparison 1"
                  dense
                />
              </v-col>
              <v-col cols="12" md="3">
                <custom-select
                  v-if="stackedSplit || splitBy === ''"
                  v-model="chartType3"
                  class="mt-0"
                  :items="chartTypes"
                  :disabled="chartType2 === '' && chartType3 === ''"
                  label="Comparison 2"
                  dense
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-radio-group
                  v-model="chartDisplay"
                  row
                  class="ml-4 mt-0 radio-group-push-right"
                  hide-details
                >
                  <v-radio label="Bar" value="bar" />
                  <v-radio label="Line" value="area" />
                  <v-btn
                    v-if="!hideAdvancedReport"
                    icon
                    small
                    class="ml-4 mt-1"
                    @click="redrawChart"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <custom-select
                  v-model="groupBy"
                  :items="groupByOptions"
                  label="Group By"
                  dense
                />
              </v-col>
              <v-col cols="12" md="3">
                <custom-select
                  v-model="splitBy"
                  :disabled="selectedRestaurantIds.length < 2"
                  :items="splitByOptions"
                  label="Split By"
                  dense
                />
                <v-switch
                  v-if="splitBy != ''"
                  v-model="stackedSplit"
                  :label="stackedSplit ? 'Stacked' : 'Side-by-side'"
                  style="background: transparent"
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="3">
                <custom-select
                  v-model="functionType"
                  :items="functionTypes"
                  label="Function"
                  dense
                />
              </v-col>
              <v-col cols="12" md="3" class="text-right">
                <v-btn
                  v-if="hideAdvancedReport"
                  color="primary"
                  @click="hideAdvancedReport = false"
                >
                  Compile Advanced Report
                </v-btn>
                <v-btn
                  v-else
                  color="primary"
                  text
                  @click="hideAdvancedReport = true"
                >
                  Hide Advanced Report
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text v-if="!hideAdvancedReport" class="pa-0">
            <vue-apex-charts
              v-if="filteredOrdersGrouped.length && !hideAdvancedChart"
              ref="orderschart"
              height="400"
              :type="chartDisplay"
              :options="{
                colors: chartColors,
                chart: {
                  id: 'orders',
                  group: 'bydate',
                  zoom: {
                    enabled: false,
                  },
                  stacked: stackedSplit && splitBy !== '',
                  toolbar: {
                    show: true,
                    tools: {
                      download: false,
                      selection: true,
                      zoom: true,
                      zoomin: true,
                      zoomout: true,
                      pan: true,
                      reset: true,
                    },
                  },
                },
                xaxis: {
                  categories: filteredOrdersGrouped.map(o => {
                    switch (groupBy) {
                      case 'Month':
                        return moment(o.day).format('MMM')
                      case 'Week':
                        return moment(o.day).format('MMM D')
                      case 'Day':
                        return moment(o.day).format('ddd, MMM D')
                      case 'Hour':
                        return moment(o.day).format('ddd hA')
                    }
                  }),
                },
                yaxis: {
                  labels: {
                    formatter: function (val) {
                      if (chartType !== 'count') {
                        return formatPrice(val * 100, true)
                      }
                      return val
                    },
                  },
                },
                tooltip: {
                  theme: $vuetify.theme.dark ? 'dark' : 'light',
                  y: {
                    formatter: function (val) {
                      if (chartType !== 'count') {
                        return formatPrice(val * 100, true)
                      }
                      return val
                    },
                  },
                },
                legend: {
                  show: chartSeries.length > 1,
                  onItemClick: {
                    toggleDataSeries: false,
                  },
                },
                plotOptions: {
                  bar: {
                    columnWidth: '50%',
                    distributed: chartSeries.length === 1 && splitBy === '',
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: 'smooth',
                  width: 1,
                  colors: ['#fff'],
                },
                markers: {
                  size: 4,
                  strokeColors: '#fff',
                  strokeWidth: 2,
                  hover: {
                    size: 7,
                  },
                },
              }"
              :series="chartSeries"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xl="6">
        <v-card
          :loading="loading"
          outlined
          class="mt-4"
          shaped
          style="overflow: hidden"
        >
          <v-card-title class="text-h6 text-uppercase my-2">
            Sales by {{ groupBy }}
          </v-card-title>
          <v-card-text class="pa-0">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th v-if="splitBy === ''">
                      &nbsp;
                      <!-- used to link the color to the chart-->
                    </th>
                    <th class="text-left" style="min-width: 105px">Date</th>
                    <th class="text-left">Orders</th>
                    <th
                      v-for="column in chartTypes.filter(
                        c => c.value !== '' && c.value !== 'count'
                      )"
                      :key="`col-${column.value}`"
                    >
                      {{ column.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(order, $index) in filteredOrdersGrouped"
                    :key="order.day"
                  >
                    <td v-if="splitBy === ''" class="pr-0">
                      <div
                        :style="{
                          backgroundColor: chartColors[$index % chartColors.length],
                        }"
                        style="width: 10px; height: 10px; border-radius: 50%"
                      />
                    </td>
                    <td :class="{ 'pl-1': splitBy === '' }">
                      {{ moment(order.day).format("ddd, MMM D") }}
                    </td>
                    <td>{{ order && order.orders.length }}</td>
                    <td
                      v-for="column in chartTypes.filter(
                        c => c.value !== '' && c.value !== 'count'
                      )"
                      :key="`col-${order.day}-${column.value}`"
                    >
                      {{ getTotal(column.value, order.day, null, true) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import VueApexCharts from "vue-apexcharts"
import colors from "vuetify/lib/util/colors"
import mixins from "../../Sales/mixins/getOrderRefunds.js"
import CustomDateRangeFilter from "../../shared/forms/datetime/CustomDateRangeFilter.vue"
import CustomSelect from "../../shared/CustomSelect.vue"
import moment from "moment"
import SummaryStats from "./SummaryStats"
import formatPrice from "@/mixins/formatPrice"
import capitalize from "@/mixins/capitalize.js"

export default {
  name: "sales-analytics",
  components: {
    VueApexCharts,
    CustomDateRangeFilter,
    CustomSelect,
    SummaryStats,
  },
  mixins: [mixins, formatPrice, capitalize],
  data() {
    return {
      loading: false,
      hideAdvancedChart: false,
      hideAdvancedReport: true,
      dates: [
        moment().subtract(7, "d").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      selectedRestaurantIds: [],
      selectedMenuItemIds: [],
      selectedMenuItemCategoryIds: [],
      selectedSources: [],
      orderStatusFilter: ["open", "completed"],
      chartDisplay: "bar",
      chartType: "count",
      chartType2: "",
      chartType3: "",
      stackedSplit: true,
      groupByOptions: ["Month", "Week", "Day", "Hour"],
      groupBy: "Day",
      splitByOptions: [
        { text: "None", value: "" },
        { text: "Restaurant", value: "restaurant" },
        { text: "Source", value: "source" },
        { text: "New vs. Returning", value: "newVsReturning" },
      ],
      splitBy: "",
      functionTypes: [
        { text: "Sum", value: "sum" },
        { text: "Average", value: "average" },
      ],
      functionType: "sum",
    }
  },
  computed: {
    ...mapState([
      "allOrders",
      "currentUserRole",
      "customers",
      "restaurants",
      "firebaseRefs",
      "settings",
      "menuItemCategories",
      "menuItems",
      "taxes",
    ]),
    ...mapGetters(["getRestaurantName"]),
    orderSourceOptions() {
      return [
        "Kiosk",
        "UberEats",
        "Uber Eats",
        "SkipTheDishes",
        "DoorDash",
        "Mobile",
        "Web",
      ]
      // return [...new Set(this.filteredOrders.map(order => order.source))]
    },
    menuItemOptions() {
      return this.filterMenuItemsBySelectedCategory()
    },
    menuItemCategoryOptions() {
      return this.filterCategoriesBySelectedRestaurantId()
    },
    paymentProvider() {
      return this.settings.paymentProvider
        ? this.capitalize(this.settings.paymentProvider)
        : "Payment Processing"
    },
    chartTypes() {
      return [
        { text: "None", value: "" },
        { text: "Number of Orders", value: "count" },
        { text: "Subtotal", value: "subtotal" },
        { text: "Discount", value: "discount" },
        { text: "Taxes", value: "taxes" },
        { text: "Tips", value: "tips" },
        { text: "Refunds", value: "refunds" },
        { text: "Total Paid", value: "totalPaid" },
        { text: "Platform Fee", value: "platformFee" },
        { text: this.paymentProvider + " Fee", value: "paymentProcessingFee" },
        { text: "Total Distributed", value: "totalDistributed" },
      ]
    },
    chartSeries() {
      let series = []
      if (this.chartType) {
        series = [
          ...series,
          ...this.getSeriesForChartType(this.chartType, "series1"),
        ]
      }
      if (this.chartType2 && (this.stackedSplit || this.splitBy === "")) {
        series = [
          ...series,
          ...this.getSeriesForChartType(this.chartType2, "series2"),
        ]
      }
      if (this.chartType3 && (this.stackedSplit || this.splitBy === "")) {
        series = [
          ...series,
          ...this.getSeriesForChartType(this.chartType3, "series3"),
        ]
      }
      return series
    },
    prevDates() {
      if (this.dates && this.dates.length === 2) {
        // base previous time period off of current dates
        const [startDate, endDate] = this.dates.map(date => moment(date))
        const range = endDate.diff(startDate, "days")
        const previousStartDate = startDate.clone().subtract(range, "days")
        const previousEndDate = startDate.clone().subtract(1, "day")

        return [
          previousStartDate.format("YYYY-MM-DD"),
          previousEndDate.format("YYYY-MM-DD"),
        ]
      }
      return []
    },
    numberOfDays() {
      return moment(this.dates[1]).diff(moment(this.dates[0]), "days")
    },
    chartColors() {
      const baseColors = [
        colors.blue.accent3,
        colors.teal.accent3,
        colors.orange.accent3,
        colors.red.accent3,
        colors.deepPurple.accent3,
        colors.cyan.accent3,
        colors.green.accent3,
        colors.yellow.accent3,
        colors.indigo.accent3,
        colors.pink.accent3,
        colors.lightGreen.accent3,
        colors.amber.accent3,
      ]
      const alternateColors = [
        colors.blue.darken2,
        colors.teal.darken2,
        colors.orange.darken2,
        colors.red.darken2,
        colors.deepPurple.darken2,
        colors.cyan.darken2,
        colors.green.darken2,
        colors.yellow.darken2,
        colors.indigo.darken2,
        colors.pink.darken2,
        colors.lightGreen.darken2,
        colors.amber.darken2,
      ]
      const alternateColors2 = [
        colors.blue.lighten3,
        colors.teal.lighten3,
        colors.orange.lighten3,
        colors.red.lighten3,
        colors.deepPurple.lighten3,
        colors.cyan.lighten3,
        colors.green.lighten3,
        colors.yellow.lighten3,
        colors.indigo.lighten3,
        colors.pink.lighten3,
        colors.lightGreen.lighten3,
        colors.amber.lighten3,
      ]
      if (this.chartSeries.length === 1 || !this.splitBy || this.stackedSplit) {
        if (this.groupBy === "Hour") {
          return baseColors.slice(0, 12)
        } else if (this.groupBy === "Day" && this.numberOfDays < baseColors.length) {
          return baseColors.slice(0, this.numberOfDays)
        } else if (
          this.groupBy === "Week" &&
          this.numberOfDays / 7 < baseColors.length
        ) {
          return baseColors.slice(0, this.numberOfDays / 7)
        } else if (
          this.groupBy === "Month" &&
          this.numberOfDays / 30 < baseColors.length
        ) {
          return baseColors.slice(0, this.numberOfDays / 30)
        }
        return baseColors
      } else {
        if (this.chartSeries.length > 1) {
          return [
            ...baseColors.slice(0, this.restaurants.length),
            ...alternateColors.slice(0, this.restaurants.length),
            ...alternateColors2.slice(0, this.restaurants.length),
          ]
        }
        // todo: add support for split by source color grouping
        else return baseColors
      }
    },
    filteredOrders() {
      return this.getFilteredOrdersForDateRange(this.dates)
    },
    filteredOrdersPreviousPeriod() {
      return this.getFilteredOrdersForDateRange(this.prevDates)
    },
    filteredOrdersGrouped() {
      return this.filteredOrdersGroupedMore(true, null)
    },
    filteredOrdersGroupedPreviousPeriod() {
      return this.filteredOrdersGroupedMore(false, null)
    },
    uniqueTaxes() {
      const uniqueTaxes = []
      this.taxes.forEach(tax => {
        if (!uniqueTaxes.some(t => this.isSameTax(t, tax))) {
          uniqueTaxes.push({
            uniqueId: this.getTaxId(tax),
            label:
              tax.name +
              " (" +
              tax.amount +
              " " +
              (tax.type === "Percentage" ? "%" : "") +
              ")",
            name: tax.name,
            amount: tax.amount,
            type: tax.type,
            isCompound: tax.isCompound,
          })
        }
      })
      return uniqueTaxes
    },
    dateFormat() {
      return "YYYY-MM-DD HH:mm:ss"
    },
  },
  watch: {
    dates() {
      this.redrawChart()
    },
    selectedSources: {
      handler: function () {
        this.redrawChart()
      },
      deep: true,
    },
    menuItemCategoryOptions() {
      this.selectedMenuItemCategoryIds = this.menuItemCategoryOptions.map(
        category => category.id
      )
      this.redrawChart()
    },
    menuItemOptions() {
      this.selectedMenuItemIds = this.menuItemOptions.map(item => item.id)
      this.redrawChart()
    },
    selectedMenuItemIds() {
      this.redrawChart()
    },
    functionType() {
      this.redrawChart()
    },
    stackedSplit() {
      this.redrawChart()
    },
  },
  async mounted() {
    this.selectedRestaurantIds = this.restaurants.map(restaurant => restaurant.id)
    this.selectedSources = this.orderSourceOptions
    this.selectedMenuItemCategoryIds = this.menuItemCategoryOptions.map(
      category => category.id
    )
    this.selectedMenuItemIds = this.menuItemOptions.map(item => item.id)
    this.loading = true
    setTimeout(() => {
      let dateRange
      if (this.dates && this.dates.length === 2) {
        dateRange = {
          startDate: new Date(this.prevDates[0]),
          endDate: new Date(this.dates[1]),
        }
      }
      this.fetchAllOrders(dateRange).then(() => {
        this.loading = false
      })
    }, 200)
  },
  methods: {
    ...mapActions(["fetchAllOrders"]),
    redrawChart() {
      this.hideAdvancedChart = true
      setTimeout(() => {
        this.hideAdvancedChart = false
      }, 100)
    },
    getSeriesForChartType(chartType, group) {
      const series = []
      if (this.splitBy === "restaurant") {
        this.restaurants
          .filter(r => this.selectedRestaurantIds.includes(r.id))
          .forEach(restaurant => {
            series.push({
              name:
                restaurant.name +
                  " " +
                  this.chartTypes.find(o => o.value === chartType)?.text || "Orders",
              group: group,
              data: this.filteredOrdersGroupedMore(true, restaurant.id).map(o => {
                if (chartType === "count") {
                  return o.orders.length
                } else return this.getTotal(chartType, o.day)
              }),
            })
          })
      } else if (this.splitBy === "source") {
        this.orderSourceOptions
          .filter(s => this.selectedSources.includes(s))
          .forEach(source => {
            series.push({
              name:
                source +
                  " " +
                  this.chartTypes.find(o => o.value === chartType)?.text || "Orders",
              group: group,
              data: this.filteredOrdersGroupedMore(this.dates, null, source).map(
                o => {
                  if (chartType === "count") {
                    return o.orders.length
                  }
                  return this.getTotal(chartType, o.day)
                }
              ),
            })
          })
      } else if (this.splitBy === "newVsReturning") {
        ;["new", "returning", "unknown"].forEach(splitCategory => {
          series.push({
            name:
              (this.chartTypes.find(o => o.value === chartType)?.text || "Orders") +
              " from " +
              splitCategory +
              " customers",
            group: group,
            data: this.filteredOrdersGroupedMore(
              true,
              null,
              null,
              splitCategory
            ).map(o => {
              if (chartType === "count") {
                return o.orders.length
              }
              return this.getTotal(chartType, o.day)
            }),
          })
        })
      } else {
        series.push({
          name: this.chartTypes.find(o => o.value === chartType)?.text || "Orders",
          data: this.filteredOrdersGrouped.map(o => {
            if (chartType === "count") {
              return o.orders.length
            }
            return this.getTotal(chartType, o.day)
          }),
        })
      }
      return series
    },
    getFilteredOrdersForDateRange(dateRange) {
      let filteredOrders = [...this.allOrders].filter(order => {
        const orderRefunds = this.getOrderRefunds(order.id)
        const orderHasFullRefund =
          orderRefunds?.length && orderRefunds.some(refund => !refund.partialRefund)
        return (
          order.status !== "Failed" && !orderHasFullRefund
          //  &&
          // (order.restaurants.length > 2 ||
          //   (order.restaurants.length > 1 &&
          //     !order.restaurants.find(r => r.id === "FHZYVWa8ydToI2AWuVXC")))
        )
      })

      // date filter
      if (dateRange?.length) {
        filteredOrders = filteredOrders.filter(order => {
          const orderDate = order.orderDate?.toDate()
          if (orderDate && moment(orderDate).isValid()) {
            return (
              // eslint-disable-next-line prettier/prettier
            moment(orderDate).isSameOrAfter(moment(dateRange[0], this.dateFormat)) &&
              moment(orderDate).isSameOrBefore(moment(dateRange[1], this.dateFormat))
            )
          }
        })
      }
      // menu item filter
      if (this.selectedMenuItemIds?.length) {
        if (this.selectedMenuItemIds?.length !== this.menuItemOptions?.length) {
          filteredOrders = filteredOrders.filter(order => {
            return (
              order.orderItems?.length &&
              order.orderItems.some(item => {
                return this.selectedMenuItemIds.includes(item.menuItemId)
              })
            )
          })
        }
      } else {
        return []
      }
      // restaurant filter
      if (this.selectedRestaurantIds?.length) {
        filteredOrders = filteredOrders.filter(order => {
          return (
            order.restaurants?.length &&
            order.restaurants.some(restaurant => {
              return this.selectedRestaurantIds.includes(restaurant.id)
            })
          )
        })
      } else {
        return []
      }
      // status filter
      if (this.orderStatusFilter?.length) {
        const includedStatuses = []
        if (this.orderStatusFilter.includes("pending")) {
          includedStatuses.push("Pending")
        }
        if (this.orderStatusFilter.includes("open")) {
          includedStatuses.push(
            "Payment Accepted",
            "Accepted",
            "In Progress",
            "Ready For Pickup"
          )
        }
        if (this.orderStatusFilter.includes("completed")) {
          includedStatuses.push("Completed", "Refunded", "Cancelled")
        }
        filteredOrders = filteredOrders.filter(order => {
          return includedStatuses.includes(order.status)
        })
      } else {
        return []
      }
      return [
        ...filteredOrders
          .map(order => {
            const refunds = this.getOrderRefunds(order.id)
            const refundTotal = refunds.reduce((total, refund) => {
              return total + refund.refundTotal
            }, 0)
            const refundRestaurants = {}
            this.restaurants.forEach(restaurant => {
              refundRestaurants[restaurant.id] = {
                id: restaurant.id,
                subtotal: refunds.reduce((total, refund) => {
                  return (
                    total +
                    parseInt(
                      refund.restaurants.filter(r => r.id === restaurant.id)?.[0]
                        ?.subtotal || 0
                    )
                  )
                }, 0),
                tip: refunds.reduce((total, refund) => {
                  return (
                    total +
                    parseInt(
                      refund.restaurants.filter(r => r.id === restaurant.id)?.[0]
                        ?.tip || 0
                    )
                  )
                }, 0),
                total: refunds.reduce((total, refund) => {
                  return (
                    total +
                    parseInt(
                      refund.restaurants.filter(r => r.id === restaurant.id)?.[0]
                        ?.total || 0
                    )
                  )
                }, 0),
                deductionAmount: refunds.reduce((total, refund) => {
                  return (
                    total +
                    parseInt(
                      refund.restaurants.filter(r => r.id === restaurant.id)?.[0]
                        ?.deductionAmount || 0
                    )
                  )
                }, 0),
              }
            })
            const totalRestaurantDeductions = Object.values(
              refundRestaurants
            ).reduce((total, r) => {
              return total + r.deductionAmount
            }, 0)
            const organizationRefunds = refundTotal - totalRestaurantDeductions
            const orderData = {
              refundRestaurants: refundRestaurants,
              id: order.id,
              date: moment(order.orderDate?.toDate()).format(this.dateFormat),
              orderNumber: order.orderNumber,
              source: this.capitalize(order.channelData?.appType) || "",
              status: order.status,
              subtotal: this.formatPrice(order.priceData?.subTotal),
              discount: this.formatPrice(order.priceData?.discount),
              taxes: this.formatPrice(
                order.priceData?.totalTaxes || order.priceData?.taxes
              ),
              tips: this.formatPrice(order.priceData?.tip),
              serviceCharge: this.formatPrice(order.priceData?.serviceCharge),
              refunds: this.formatPrice(refundTotal),
              totalPaid: this.formatPrice(order.priceData?.total - refundTotal),
              organizationFee: this.formatPrice(order.priceData?.organizationFee),
              organizationDiscount: this.formatPrice(
                order.priceData?.organizationDiscount
              ),
              organizationTip: this.formatPrice(order.priceData?.organizationTip),
              organizationPaymentProcessingFee: this.formatPrice(
                -order.priceData?.organizationPaymentProcessingFee ||
                  -order.priceData?.organizationStripeFee
              ),
              organizationRefunds: this.formatPrice(organizationRefunds),
              orgTotalReceived: this.formatPrice(
                order.priceData?.total -
                  (order.priceData?.paymentProcessingFee ||
                    order.priceData?.stripeFee) -
                  this.getRestaurantTotalNetTotals(order) -
                  organizationRefunds -
                  order.priceData?.platformFee +
                  (order.priceData?.platformPaymentProcessingFee ||
                    order.priceData?.platformStripeFee) -
                  order.priceData?.serviceCharge
              ),
              platformFee: this.formatPrice(-order.priceData?.platformFee),
              paymentProcessingFee: this.formatPrice(
                -order.priceData?.paymentProcessingFee || -order.priceData?.stripeFee
              ),
              totalDistributed: this.formatPrice(
                this.getOrderTotalDistributed(order.priceData) -
                  totalRestaurantDeductions
              ),
              refundsOrig: this.getOrderRefunds(order.id),
              userNumberOfPrevOrders:
                order.userId === "Guest" ||
                ["kiosk", "pos"].includes(order.channelData.appType)
                  ? -1
                  : order.userNumberOfPrevOrders,
              restaurants: order.restaurants,
            }
            orderData["taxesList"] = order.priceData?.taxesList
            this.uniqueTaxes.forEach(tax => {
              orderData[this.getTaxId(tax)] = this.formatPrice(
                // reduce the taxes list to find the sum for each tax
                order.priceData?.taxesList
                  .filter(t => {
                    const taxData = this.taxes.find(t2 => {
                      return t2.id === t.id
                    })
                    return this.isSameTax(taxData, tax)
                  })
                  .reduce((total, t) => {
                    return total + t.amount
                  }, 0)
              )
            })
            this.restaurants.forEach(restaurant => {
              const restaurantData = order.restaurants?.find(
                r => r.id === restaurant.id
              )
              const restaurantRefunds = refundRestaurants[restaurant.id]
              if (restaurantData) {
                orderData["posOrderId" + restaurant.id] = restaurantData.posOrderId
                orderData["subTotal" + restaurant.id] = this.formatPrice(
                  restaurantData?.subTotal
                )
                orderData["discount" + restaurant.id] = this.formatPrice(
                  restaurantData?.discount
                )
                this.taxes.forEach(tax => {
                  if (tax.restaurantIds.includes(restaurant.id)) {
                    const relevantTaxes = order.priceData?.taxesList.filter(
                      t => t.id === tax.id
                    )
                    if (relevantTaxes?.length > 0) {
                      const taxId = this.getTaxId(tax, restaurant)
                      const totalTaxAmount = relevantTaxes.reduce(
                        (sum, t) => sum + t.amount,
                        0
                      )
                      orderData[taxId] = this.formatPrice(
                        (orderData[taxId] || 0) + totalTaxAmount
                      )
                    }
                  }
                })
                orderData["taxes" + restaurant.id] = this.formatPrice(
                  restaurantData.totalTaxes || restaurantData.taxes
                )
                orderData["tips" + restaurant.id] = this.formatPrice(
                  restaurantData.tip // - restaurantRefunds.tip
                )
                orderData["grossTotal" + restaurant.id] = this.formatPrice(
                  restaurantData.grossTotal // - restaurantRefunds.total
                )
                orderData["organizationFee" + restaurant.id] = this.formatPrice(
                  -restaurantData.organizationFee
                )
                orderData["paymentProcessingFee" + restaurant.id] = this.formatPrice(
                  -restaurantData.paymentProcessingFee || -restaurantData.stripeFee
                )
                orderData["refunds" + restaurant.id] = this.formatPrice(
                  restaurantRefunds.deductionAmount
                )
                orderData["netTotal" + restaurant.id] = this.formatPrice(
                  restaurantData.netTotal - restaurantRefunds.deductionAmount
                )
              }
            })
            return orderData
          })
          .sort((a, b) => {
            return moment(b.date, this.dateFormat).diff(
              moment(a.date, this.dateFormat)
            )
          })
          .reduce((a, c) => {
            a.set(c.id, c)
            return a
          }, new Map())
          .values(),
      ]
    },
    filterCategoriesBySelectedRestaurantId() {
      const filteredMenuItems = this.menuItems
        .filter(
          item =>
            this.selectedRestaurantIds.includes(item.restaurantId) &&
            item.menuItemCategoryIds
        )
        .map(item => item.menuItemCategoryIds)
      return this.menuItemCategories.filter(category => {
        return filteredMenuItems.some(item => item?.includes(category.id))
      })
    },
    filterMenuItemsBySelectedCategory() {
      const menuItems = this.menuItems.filter(item =>
        this.selectedRestaurantIds.includes(item.restaurantId)
      )
      if (this.selectedMenuItemCategoryIds.length === 0) {
        return menuItems
      }
      return menuItems.filter(item =>
        this.selectedMenuItemCategoryIds.some(categoryId =>
          item.menuItemCategoryIds?.includes(categoryId)
        )
      )
    },
    filteredOrdersGroupedMore(
      isForCurrentDates,
      restaurantId,
      source,
      newOrReturning
    ) {
      const dates = isForCurrentDates ? this.dates : this.prevDates
      const ordersGrouped = []
      // First, initialize the ordersGrouped array with an entry for each hour/day/week/month
      // This is needed to ensure split by produces the correct columns

      let startDate = moment(dates[0], this.dateFormat)
      let endDate = moment(dates[1], this.dateFormat)
      while (startDate.isSameOrBefore(endDate)) {
        let groupByDate
        switch (this.groupBy) {
          case "Month":
            groupByDate = startDate.format("YYYY-MM")
            break
          case "Week":
            groupByDate = startDate.format("YYYY-[W]WW")
            break
          case "Day":
            groupByDate = startDate.format("YYYY-MM-DD")
            break
          case "Hour":
            groupByDate = startDate.format("YYYY-MM-DD HH")
            break
        }
        ordersGrouped.push({
          day: groupByDate,
          orders: [],
        })
        if (this.groupBy === "Hour") {
          startDate.add(1, "h")
        } else {
          startDate.add(1, this.groupBy)
        }
      }

      const orders = isForCurrentDates
        ? this.filteredOrders
        : this.filteredOrdersPreviousPeriod

      orders
        .filter(order => {
          return !restaurantId || order.restaurants.some(r => r.id === restaurantId)
        })
        .filter(order => {
          return (
            this.selectedSources.includes(order.source) &&
            (!source || order.source === source)
          )
        })
        .filter(order => {
          if (newOrReturning === "unknown") {
            return order.userNumberOfPrevOrders === -1
          } else if (newOrReturning === "new") {
            return !order.userNumberOfPrevOrders
          } else if (newOrReturning === "returning") {
            return order.userNumberOfPrevOrders && order.userNumberOfPrevOrders > 0
          }
          return newOrReturning === undefined
        })
        .forEach(order => {
          const orderDate = moment(order.date, this.dateFormat)
          let groupByDate
          switch (this.groupBy) {
            case "Month":
              groupByDate = orderDate.format("YYYY-MM")
              break
            case "Week":
              groupByDate = orderDate.format("YYYY-[W]WW")
              break
            case "Day":
              groupByDate = orderDate.format("YYYY-MM-DD")
              break
            case "Hour":
              groupByDate = orderDate.format("YYYY-MM-DD HH")
              break
          }
          ordersGrouped.find(o => o.day === groupByDate)?.orders.push(order)
        })
      // ensure that each day has an entry
      startDate = moment(dates[0], this.dateFormat)
      endDate = moment(dates[1], this.dateFormat)
      if (this.groupBy === "Day") {
        while (startDate.isSameOrBefore(endDate)) {
          const date = startDate.format("YYYY-MM-DD")
          if (!ordersGrouped.some(o => o.day === date)) {
            ordersGrouped.push({
              day: date,
              orders: [],
            })
          }
          startDate.add(1, "d")
        }
      } else if (this.groupBy === "Hour") {
        // ensure that each hour has an entry between 9AM and 9PM
        const startDate = moment(dates[0], this.dateFormat).hour(9)
        const endDate = moment(dates[1], this.dateFormat).hour(21)
        while (startDate.isSameOrBefore(endDate)) {
          const date = startDate.format("YYYY-MM-DD HH")
          if (!ordersGrouped.some(o => o.day === date) && startDate.hour() >= 9) {
            ordersGrouped.push({
              day: date,
              orders: [],
            })
          }
          if (startDate.hour() === 21) {
            startDate.add(1, "d")
            startDate.hour(9)
          } else {
            startDate.add(1, "h")
          }
        }
      }
      // return it sorted by day ascending
      return ordersGrouped.sort((a, b) => {
        return moment(a.day).diff(moment(b.day))
      })
    },
    getOrderDate(date) {
      return moment(date, this.dateFormat).format("YYYY-MM-DD")
    },
    getOrderTime(date) {
      return moment(date, this.dateFormat).format("HH:mm:ss")
    },
    getOrderTotalDistributed(data) {
      return (
        (data?.total || 0) -
        (data?.organizationFee || 0) -
        (data?.platformFee || 0) -
        (data?.paymentProcessingFee || data?.stripeFee || 0) -
        (data?.serviceCharge || 0)
      )
    },
    getRestaurantTotalNetTotals(data) {
      let total = 0
      data.restaurants.forEach(restaurant => {
        total += restaurant.netTotal || 0
      })
      return total
    },
    capitalize(string) {
      if (!string) return string
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    getCustomerName(userId) {
      if (userId === "Guest") return userId
      return this.customers.find(customer => customer.uid == userId) != undefined
        ? this.customers.find(customer => customer.uid == userId).displayName
        : "N/A"
    },
    getTaxId(tax, restaurant = null) {
      let uniqueId =
        tax.name +
        parseFloat(tax.amount) +
        tax.type +
        (tax.isCompound ? "Compound" : "")
      if (restaurant) {
        uniqueId += restaurant.name.replace(/[^a-zA-Z0-9-]/g, "")
      }
      return uniqueId
    },
    isSameTax(tax1, tax2) {
      return (
        tax1 &&
        tax2 &&
        tax1.name === tax2.name &&
        tax1.amount === tax2.amount &&
        tax1.type === tax2.type &&
        tax1.isCompound === tax2.isCompound
      )
    },
    getTotal(column, day, restaurantId, includeDollarSign = false) {
      const restaurantColumnMapping = {
        subtotal: "subTotal",
        discount: "discount",
        taxes: "taxes",
        tips: "tip",
        grossTotal: "grossTotal",
        organizationFee: "organizationFee",
        paymentProcessingFee: "paymentProcessingFee",
        refunds: "refunds",
        netTotal: "netTotal",
      }
      const total =
        this.filteredOrdersGrouped
          .find(o => o.day === day)
          ?.orders.reduce((total, order) => {
            if (restaurantId) {
              const restaurantData = order.restaurants.find(
                r => r.id === restaurantId
              )
              if (restaurantData) {
                return (
                  total + parseFloat(order[restaurantColumnMapping[column]] || 0)
                )
              } else return total
            } else {
              return total + parseFloat(order[column] || 0)
            }
          }, 0) * 100
      if (this.functionType === "average") {
        return this.formatPrice(
          total / this.filteredOrdersGrouped.find(o => o.day === day)?.orders.length,
          includeDollarSign
        )
      }
      return this.formatPrice(total, includeDollarSign)
    },
    async datesRangeUpdated(dates) {
      this.loading = true
      setTimeout(() => {
        // if more than 7 days and currently grouped by hour, change to group by day
        if (
          moment(dates[1]).diff(moment(dates[0]), "months") > 7 &&
          (this.groupBy === "Hour" ||
            this.groupBy === "Day" ||
            this.groupBy === "Week")
        ) {
          this.groupBy = "Month"
        } else if (
          moment(dates[1]).diff(moment(dates[0]), "weeks") > 7 &&
          (this.groupBy === "Hour" || this.groupBy === "Day")
        ) {
          this.groupBy = "Week"
        } else if (
          moment(dates[1]).diff(moment(dates[0]), "days") > 7 &&
          this.groupBy === "Hour"
        ) {
          this.groupBy = "Day"
        }
        this.dates = dates
        let dateRange
        if (this.dates && this.dates.length === 2) {
          dateRange = {
            startDate: new Date(this.prevDates[0]),
            endDate: new Date(this.dates[1]),
          }
        }
        this.fetchAllOrders(dateRange).then(() => {
          this.loading = false
        })
      }, 200)
    },
  },
}
</script>

<style lang="scss">
.analytics-filter-wrapper.light-background {
  background: var(--v-light-background-base);
  border-radius: 5px;
  border: solid 1px var(--v-light-border-base) !important;
  padding: 5px 0;
  max-width: 100%;
  width: 100%;
  z-index: 1;
}
.analytics-filter-wrapper .v-input:not(.v-input--checkbox) {
  background: var(--v-background-base);
}
.analytics-filter-wrapper .v-input fieldset {
  border-color: var(--v-light-border-base);
}
.radio-group-push-right {
  flex: auto !important;
  .v-input--radio-group__input {
    justify-content: flex-end;
  }
}
</style>
