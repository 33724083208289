<template>
  <v-card class="mt-4" outlined :loading="loading" shaped style="overflow: hidden">
    <v-row align="center" class="my-n8">
      <v-col cols="12" md="8" lg="7">
        <v-row>
          <v-col cols="12">
            <v-card-title class="text-h6 text-uppercase my-n6">
              Base Report
            </v-card-title>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <summary-stat-panel title="Total Sales" :stat="'CA' + totalSales" />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <summary-stat-panel title="Number of Orders" :stat="orderCount" />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <summary-stat-panel
              title="Average Ticket"
              :stat="'CA' + averageTicket"
            />
          </v-col>
        </v-row>
        <v-row class="mt-n2">
          <v-col cols="12" sm="6" md="4">
            <summary-stat-panel title="New Customers" :stat="newCustomerCount" />
          </v-col>
          <v-col cols="12" sm="6" md="8">
            <summary-stat-panel
              title="Returning Customers"
              :stat="returningCustomerCount"
            />
          </v-col>
          <v-col v-if="unknownCustomerCount" cols="12" class="mx-4 mt-n4 grey--text">
            <small>
              There are {{ unknownCustomerCount }} customers from kiosk and other
              sources that cannot be determined as new or returning.
            </small>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4" lg="5" class="py-8">
        <v-card class="elevation-0">
          <v-card-text>
            <vue-apex-charts
              width="100%"
              height="250"
              :options="chartOptions"
              :series="chartSeries"
            ></vue-apex-charts>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import SummaryStatPanel from "@/AuthenticatedContent/shared/SummaryStatPanel"
import VueApexCharts from "vue-apexcharts"
import moment from "moment"
import formatPrice from "@/mixins/formatPrice"
import colors from "vuetify/lib/util/colors"

export default {
  components: {
    VueApexCharts,
    SummaryStatPanel,
  },
  mixins: [formatPrice],
  props: {
    filteredOrders: {
      type: Array,
      required: true,
    },
    // TODO: Show change from previous period using this prop
    filteredOrdersPreviousPeriod: {
      type: Array,
      required: true,
    },
    filteredOrdersGrouped: {
      type: Array,
      required: true,
    },
    filteredOrdersGroupedPreviousPeriod: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        stroke: {
          dashArray: [0, 5],
        },
        colors: [colors.blue.base, colors.grey.lighten1],
        legend: {
          show: false,
        },
        xaxis: {
          title: "Timeframe",
          categories: this.filteredOrdersGrouped.map(group => group.day),
          tickAmount: 5,
          labels: {
            formatter: val => moment(val).format("MMM D"),
          },
        },
        yaxis: {
          title: "Total Sales",
          labels: {
            formatter: val => `CA$${Math.round(val)}`,
          },
          opposite: true,
        },
      },
      totalSales: 0,
      orderCount: 0,
      averageTicket: 0,
      newCustomerCount: 0,
      returningCustomerCount: 0,
      unknownCustomerCount: 0,
    }
  },
  computed: {
    chartSeries() {
      return [
        {
          name: "This period",
          data: this.createSeriesData(this.filteredOrdersGrouped),
        },
        {
          name: "Last period",
          data: this.createSeriesData(this.filteredOrdersGroupedPreviousPeriod),
          stroke: {
            dashArray: 2,
          },
          fill: {
            colors: colors.grey.lighten1,
          },
        },
      ]
    },
  },
  watch: {
    filteredOrdersGrouped() {
      this.calculateStats()
    },
  },
  mounted() {
    this.calculateStats()
  },
  methods: {
    createSeriesData(data) {
      return data.map(group => ({
        x: group.day, // X-axis label
        y: group.orders.reduce((total, order) => total + order.totalPaid, 0), // Y-axis value
      }))
    },
    getTotal(column, formatPrice = false, divideBy = 1) {
      const total = this.filteredOrdersGrouped.reduce((total, group) => {
        return (
          total +
          group.orders.reduce((sum, order) => sum + parseFloat(order[column]), 0)
        )
      }, 0)
      return formatPrice ? this.formatPrice((total * 100) / divideBy, true) : total
    },
    calculateStats() {
      this.totalSales = this.getTotal("totalPaid", true)
      this.orderCount = this.filteredOrders.length
      this.averageTicket = this.getTotal("totalPaid", true, this.orderCount)
      this.newCustomerCount = this.filteredOrders.filter(
        order => !order.userNumberOfPrevOrders
      )?.length
      this.returningCustomerCount = this.filteredOrders.filter(
        order => order.userNumberOfPrevOrders && order.userNumberOfPrevOrders > 0
      )?.length
      this.unknownCustomerCount = this.filteredOrders.filter(
        order => order.userNumberOfPrevOrders === -1
      )?.length
    },
  },
}
</script>
