<template>
  <v-expansion-panels flat accordion>
    <v-expansion-panel
      style="background: transparent !important"
      :readonly="
        !order.channelData.deliveryAddress ||
        !order.channelData.deliveryAddress.source
      "
    >
      <v-expansion-panel-header
        class="pa-0"
        :class="{ 'justify-end': rightAlign }"
        :hide-actions="
          !order.channelData.deliveryAddress ||
          !order.channelData.deliveryAddress.source
        "
      >
        {{ customerInfo }}
      </v-expansion-panel-header>
      <v-expansion-panel-content
        v-if="
          order.channelData.deliveryAddress &&
          order.channelData.deliveryAddress.source
        "
        class="mx-n6"
      >
        <p class="mt-n3 mb-0">
          {{
            order.channelData.deliveryAddress.source +
            " " +
            order.channelData.deliveryAddress.extraAddressInfo
          }}
        </p>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel style="background: transparent !important" class="mt-n4">
      <v-expansion-panel-header class="pa-0" hide-actions>
        <span
          v-if="
            order.orderNumber ||
            (order.channelData &&
              (order.channelData.referenceId || order.channelData.appDisplayId))
          "
          class="text--disabled"
          :class="{ 'text-right': rightAlign }"
          :title="clickOrTapText + ` to show order IDs`"
        >
          {{
            (isNextGenOrder && `Order #` + order.orderNumber) ||
            (order.channelData &&
              `Ref. ` +
                (order.channelData.referenceId || order.channelData.appDisplayId))
          }}
          <span v-if="order.doordashData && order.doordashData.supportReference">
            <br />
            DoorDash Ref. {{ order.doordashData.supportReference }}
          </span>
        </span>
        <span v-else class="text--disabled" :title="order.id">
          {{ order.id }}
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content
        v-if="
          order.orderNumber ||
          (order.channelData &&
            (order.channelData.referenceId || order.channelData.appDisplayId))
        "
        class="mx-n6 mt-n6"
        style="min-height: 20px"
      >
        <br />
        <p>
          <span class="text--disabled">NextGen Order ID:</span>
          <br />
          {{ order.id }}
        </p>
        <p>
          <span class="text--disabled">Channel Order ID:</span>
          {{ order.channelData.appOrderId || order.channelData.referenceId }}
        </p>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "order-detail-source",
  props: {
    order: {
      type: Object,
      required: true,
    },
    rightAlign: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clickOrTapText: "Click or tap",
    }
  },
  computed: {
    ...mapState(["customers"]),
    isNextGenOrder() {
      return ["kiosk", "mobile", "web"].includes(this.order.channelData?.appType)
    },
    customerInfo() {
      let customerInfo
      if (this.order.tableNumber) {
        customerInfo = this.order.tableNumber
      } else if (this.order.userId) {
        customerInfo = this.getCustomerName(this.order.userId)
      } else {
        customerInfo = "Customer"
      }
      return customerInfo
    },
  },
  mounted() {
    this.clickOrTapText = window.navigator?.maxTouchPoints ? "Tap" : "Click"
  },
  methods: {
    getCustomerName(userId) {
      if (userId === "Guest") return userId
      return this.customers?.find(customer => customer.uid == userId) != undefined
        ? this.customers.find(customer => customer.uid == userId).displayName
        : null
    },
  },
}
</script>
