var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(_vm.orderType)+" "),_c('br')]),(
      [
        'Uber Eats',
        'UberEats',
        'DoorDash',
        'Skip The Dishes',
        'Ritual',
        'Square',
        'Square Online',
      ].includes(_vm.orderSource)
    )?_c('span',{attrs:{"small":""}},[(_vm.orderSource === 'UberEats' || _vm.orderSource === 'Uber Eats')?_c('img',{staticClass:"mx-n2 mt-1",attrs:{"src":require('@/assets/logos/ubereats.webp'),"width":"80"}}):_vm._e(),(_vm.orderSource === 'Square' || _vm.orderSource === 'Square Online')?_c('img',{staticClass:"mx-n2 mt-1",attrs:{"src":require('@/assets/logos/square.webp'),"width":"80"}}):_vm._e(),(_vm.orderSource === 'Square Online')?_c('span',[_c('br'),_vm._v(" Online ")]):(_vm.orderSource === 'DoorDash')?_c('img',{staticClass:"mx-n4 mt-1",attrs:{"src":require('@/assets/logos/doordash.webp'),"width":"90"}}):(_vm.orderSource === 'Skip The Dishes')?_c('img',{staticClass:"mx-n2 mt-1",attrs:{"src":require('@/assets/logos/skip.webp'),"width":"80"}}):_vm._e(),(_vm.orderSource === 'Ritual')?_c('img',{staticClass:"mx-n2 mt-1",attrs:{"src":require('@/assets/logos/ritual.webp'),"width":"80"}}):_vm._e(),(_vm.order.channelData && _vm.order.channelData.deliverectPOS)?_c('span',{staticClass:"text--disabled"},[_c('br'),_vm._v(" Deliverect ")]):_vm._e()]):_c('span',{staticClass:"text--disabled",staticStyle:{"text-transform":"capitalize"},attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.orderSource)+" ")]),(_vm.hasDeliveryData)?_c('span',[_c('br'),_c('a',{staticClass:"custom-link",attrs:{"target":"_blank","href":_vm.order.channelData?.doordash?.trackingUrl}},[_vm._v(" Track Order ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }