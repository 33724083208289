<template>
  <div>
    <span style="text-transform: capitalize">
      {{ orderType }}
      <br />
    </span>
    <span
      v-if="
        [
          'Uber Eats',
          'UberEats',
          'DoorDash',
          'Skip The Dishes',
          'Ritual',
          'Square',
          'Square Online',
        ].includes(orderSource)
      "
      small
    >
      <img
        v-if="orderSource === 'UberEats' || orderSource === 'Uber Eats'"
        :src="require('@/assets/logos/ubereats.webp')"
        class="mx-n2 mt-1"
        width="80"
      />
      <img
        v-if="orderSource === 'Square' || orderSource === 'Square Online'"
        :src="require('@/assets/logos/square.webp')"
        class="mx-n2 mt-1"
        width="80"
      />
      <span v-if="orderSource === 'Square Online'">
        <br />
        Online
      </span>
      <img
        v-else-if="orderSource === 'DoorDash'"
        :src="require('@/assets/logos/doordash.webp')"
        class="mx-n4 mt-1"
        width="90"
      />
      <img
        v-else-if="orderSource === 'Skip The Dishes'"
        :src="require('@/assets/logos/skip.webp')"
        class="mx-n2 mt-1"
        width="80"
      />
      <img
        v-if="orderSource === 'Ritual'"
        :src="require('@/assets/logos/ritual.webp')"
        class="mx-n2 mt-1"
        width="80"
      />
      <span
        v-if="order.channelData && order.channelData.deliverectPOS"
        class="text--disabled"
      >
        <br />
        Deliverect
      </span>
    </span>
    <span v-else class="text--disabled" style="text-transform: capitalize" small>
      {{ orderSource }}
    </span>
    <span v-if="hasDeliveryData">
      <br />
      <a
        class="custom-link"
        target="_blank"
        :href="order.channelData?.doordash?.trackingUrl"
      >
        Track&nbsp;Order
      </a>
    </span>
  </div>
</template>

<script>
export default {
  name: "order-detail-source",
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    orderType() {
      if (this.order.channelData?.orderType) {
        return this.order.channelData.orderType
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, str => str.toUpperCase())
      } else {
        return "N/A"
      }
    },
    orderSource() {
      if (this.order.channelData?.appType) {
        return this.order.channelData.appType
      } else {
        return "N/A"
      }
    },
    hasDeliveryData() {
      return this.order.channelData?.doordash != null
    },
  },
}
</script>

<style>
.custom-link {
  color: #2196f3 !important;
  text-decoration: none;
}
.custom-link:hover {
  text-decoration: underline;
}
</style>
