export default {
  methods: {
    getUniqueTaxes(taxes = this.taxes) {
      const uniqueTaxes = []
      taxes.forEach(tax => {
        if (!uniqueTaxes.some(t => this.isSameTax(t, tax))) {
          uniqueTaxes.push({
            uniqueId: this.getTaxId(tax),
            label:
              tax.name +
              " (" +
              tax.amount +
              " " +
              (tax.type === "Percentage" ? "%" : "") +
              ")",
            name: tax.name,
            amount: tax.amount,
            type: tax.type,
            isCompound: tax.isCompound,
          })
        }
      })
      return uniqueTaxes
    },
    isSameTax(tax1, tax2) {
      return (
        tax1 &&
        tax2 &&
        tax1.name === tax2.name &&
        tax1.amount === tax2.amount &&
        tax1.type === tax2.type &&
        tax1.isCompound === tax2.isCompound
      )
    },
    getTaxId(tax, restaurant = null) {
      let uniqueId =
        tax.name +
        parseFloat(tax.amount) +
        tax.type +
        (tax.isCompound ? "Compound" : "")
      if (restaurant) {
        uniqueId += restaurant.name.replace(/[^a-zA-Z0-9-]/g, "")
      }
      return uniqueId
    },
  },
}
